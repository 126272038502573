import React, { useEffect, useRef, useState } from 'react'
import PanelWrapper from "../../meta/PanelWrapper";
import PanelContent from "../../meta/PanelContent";
import PanelTitle from '../../meta/PanelTitle'
import QRCode from "react-qr-code";
import { Clock, MapPin } from '@phosphor-icons/react'

export type GremiumPanelDefinition = {
    gremien: [Gremium]
}

type Gremium = {
    name: string,
    description: string,
    link: string,
    time: string,
    location: string
}

const GremiumPanel = (props: {definition: GremiumPanelDefinition}) => {
    const [gremium, setGremium] = useState<Gremium>(props.definition.gremien[0]);
    const cycle = useRef<number>(0);

    useEffect(() => {
        const update = async () => {
            setGremium(props.definition.gremien[cycle.current++ % props.definition.gremien.length]);
            console.log(gremium);
            console.log(cycle.current);
        }

        update();
        const interval = setInterval(update, 20 * 1000);

        return () => {
            clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PanelWrapper>
            <PanelTitle title={"Termine"}/>
            <PanelContent>
                <div className={"flex flex-row"}>
                    <div className={"flex-1"}>
                        <h2 className={"text-xl font-semibold mt-2"}>{gremium.description}</h2>
                        <div className={"flex flex-row gap-4"}>
                            <p className={"text-sm text-gray-400"}>
                                <Clock size={20} className={"inline mb-1.5 mr-1"}/>
                                {gremium.time}
                            </p>
                            <p className={"text-sm text-gray-400"}>
                                <MapPin size={20} className={"inline mb-1.5 mr-1"}/>
                                {gremium.location}
                            </p>
                        </div>
                    </div>
                    <div className={""}>
                        <QRCode
                          value={gremium.link}
                          className={"h-24 w-24"}
                          fgColor={"#ffffff"}
                          bgColor={"#18181b"}
                        />
                    </div>
                </div>
            </PanelContent>
        </PanelWrapper>
    );
};

export default GremiumPanel;
