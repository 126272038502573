import React, {useEffect, useRef} from 'react';
import PanelWrapper from "../../meta/PanelWrapper";
import PanelTitle from "../../meta/PanelTitle";
import PanelContent from "../../meta/PanelContent";

type MensaJetztAPIResponse = {
  day: string,
  date: string,
  attendance: Attendee[]
}

type Attendee = {
  "name": string,
  "name_modifiers": string,
  "time": string,
  "canteen": string,
  "color": string
}

type Attendance = { [time: string]: Attendee[] }

const MensaJetztPanel = () => {
  const day = useRef<string>("");
  const attendance = useRef<Attendance>({});
  const marqueeContent = useRef<string>("Niemand :(");

  useEffect(() => {
    const update = async () => {
      try {
        // Request the API
        const request = await fetch(`https://mensa.jetzt/api/entries/`);

        if (request.status !== 200) {
          day.current = "";
          attendance.current = {};
          return;
        }

        const data = await request.json() as MensaJetztAPIResponse;

        attendance.current = data.attendance.reduce<Attendance>((accu, curr) => {
          const keys = Object.keys(accu);

          if(keys.includes(curr.time)) {
            return {
              ...accu,
              [curr.time]: [
                ...accu[curr.time],
                curr
              ]
            }
          } else {
            return {
              ...accu,
              [curr.time]: [curr]
            }
          }
        }, {});

        let marquee = "";

        for(const time of Object.keys(attendance.current).sort()) {
          marquee += "  |  " + time + ": ";

          const attendants = attendance.current[time].map(a => a.name).join(", ")

          marquee += attendants
        }

        if(marquee.trim() === "") {
          marqueeContent.current = "Niemand :(";
        } else {
          marqueeContent.current = marquee + marquee + marquee + marquee + marquee;
        }
      }
      catch (e) {
        console.warn("MensaPlan not showing data because", e);
        day.current = "";
        attendance.current = {};
        marqueeContent.current = "Niemand :(";
      }
    }

    update();
    const interval = setInterval(update, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PanelWrapper className={"relative"}>
      <PanelTitle title={"Mensa.jetzt"} info={day.current} />
      <PanelContent>
        {/* @ts-ignore */}
        <marquee behaviour={"slide"}> {/* eslint-disable-line jsx-a11y/no-distracting-elements */}
          {marqueeContent.current}
        {/* @ts-ignore */}
        </marquee>
      </PanelContent>
    </PanelWrapper>
  );
};

export default MensaJetztPanel;
